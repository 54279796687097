import * as React from 'react'
import { graphql, Link } from 'gatsby'

import IndexLayout from '../components/layouts'
// import SubHeader from '../components/Landing/Subheader';
import {Pricing3} from '../components/blocks/pricing';

const TestPage = ({ data, location }) => (
  <IndexLayout>
    {/* <SubHeader /> */}
    <Pricing3 />
  </IndexLayout>

)
 
export default TestPage
